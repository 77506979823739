<template>
	<div>
		<searchCard>
			<div class="flex">
				<vSelect class="w-1/6" v-model="opt.dateType" :clearable="false" :options="dateTypeOpts" />

				<div class="flex top-1.5 ml-8">
					<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value=""
						>전체기간
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
				</div>
				<datePicker class="w-1/7" model="opt.startDate" />
				<span class="p-2 lh-6">~</span>
				<datePicker class="w-1/7" model="opt.endDate" />

				<div class="flex ml-8 pt-2">
					<h6>상태</h6>
					<b-form-checkbox v-for="v in stateOpts" class="ml-4" v-model="opt.state" :value="v.value"
						>{{ v.label }}
					</b-form-checkbox>
				</div>
			</div>
			<div class="flex mt-4">
				<div class="w-1/6">
					<h6>페널티 유형</h6>
					<vSelect class="" v-model="opt.penaltyType" :clearable="false" :options="penaltyTypeOpts" />
				</div>
				<div class="w-1/6 mx-8">
					<h6>페널티 사유</h6>
					<vSelect class="" v-model="opt.penaltyReason" :clearable="false" :options="penaltyReasonOpts" />
				</div>
				<div class="w-1/8">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6>&nbsp;</h6>
					<div>
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							v-b-tooltip.hover.html
							@keyup.enter="search"
							title="핫티스트 통합검색 대상 필드:<br>핫트아이디, 이름, 휴대폰번호, 인스타아이디<br>※ 딜 정보, 메모는 제외"
						/>
					</div>
				</div>
				<div>
					<h6 class="block">&nbsp;</h6>
					<b-button class="px-10 top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>
		<b-card>
			<div class="flex-between mb-4">
				<b-button @click="addPenalty" variant="purple">페널티 등록하기</b-button>
				<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
			</div>
			<tb :child="child" :inf="inf" :res="res" />
		</b-card>
		<penaltyAddPopup :pr="ths" :typeOpts="popupTypeOpts" cName="penaltyAddPopup" />
		<penaltyEditPopup :pr="ths" :reasonOpts="popupReasonOpts" cName="penaltyEditPopup" />
	</div>
</template>

<script>
import penaltyAddPopup from 'pages/penaltyAddPopup'
import penaltyEditPopup from 'pages/penaltyEditPopup'

const dateTypeOpts = [
		{ label: '시작일', value: 'PENALTY_STARTDATE' },
		{ label: '종료일', value: 'PENALTY_ENDDATE' },
		{ label: '업데이트일', value: 'PENALTY_UPDATEDATE' },
	],
	now = new Date(),
	stateOpts = [
		{ label: '적용중', value: 'PENALTY_IN_PROCESS' },
		{ label: '대기', value: 'PENALTY_RESERVED' },
		{ label: '종료', value: 'PENALTY_FINISHED' },
	],
	searchKeyOpts = [
		{ label: '핫티스트 통합검색', value: '' },
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '휴대폰번호', value: 'phone' },
		{ label: '인스타아이디', value: 'currentInstaId' },
		{ label: '메모', value: 'comment' },
	],
	defOpt = {
		period: 'period',
		dateType: dateTypeOpts[2],
		startDate: new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
		endDate: now,
		state: stateOpts.map(v => v.value),
		penaltyType: '',
		penaltyReason: '',
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}
let ths

export default {
	components: { penaltyAddPopup, penaltyEditPopup },
	data() {
		return {
			dateTypeOpts,
			stateOpts,
			penaltyTypeOpts: [],
			penaltyReasonOpts: [],
			searchKeyOpts,

			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '이름', key: 'memberName', size: 4 },
				{ title: '인스타아이디', key: 'currentInstaId', model: 'insta', size: 6 },
				{ title: '등급', key: 'hottistClass', size: 2 },
				{ title: '페널티유형', key: 'penaltyTypeValue', size: 4 },
				{ title: '사유', key: 'penaltyReasonValue', size: 6 },
				{ title: '상태', key: 'state', size: 4 },

				{ title: '시작일', key: 'startDate', size: 6, sortable: 1 },
				{ title: '종료일', key: 'endDate', size: 6, sortable: 1 },
				{ title: '업데이트일', key: 'updateDt', size: 6, sortable: 1 },

				{
					title: '페널티 이력',
					model: 'button',
					size: 4,
					text: item => item.penaltyCnt,
					bVariant: 'no',
					class: 'text-orange',
					func: item => {
						this.child.open(item)
					},
				},
				{
					title: '수정/삭제',
					model: 'editDelete',
					size: 4,
					vIf1: item => item.state != '종료',
					vIf2: item => item.state != '종료',
					func1: this.editPenalty,
					func2: this.removePenalty,
				},
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 3 },
			],
			child: {
				inf: [
					{ title: 'No.', model: 'idx', size: 2 },
					{ title: '시작일', key: 'startDate', size: 6 },
					{ title: '종료일', key: 'endDate', size: 6 },
					{ title: '페널티 유형', key: 'penaltyType', size: 6 },
					{ title: '사유', key: 'penaltyReasonValue', size: 9, notCenter: 1 },
					{ title: '수정일', key: 'updateDt', size: 9 },
					//date 키값 형식이 왜이래...
				],
				tbClass: 'w-1/2 mx-auto',
				limit: 3,
				open(item) {
					//페널티 리스트 선택
					//기존에 열린 다른 자식 항목은 닫기
					ths.res.list.map(another => {
						if (another != item) another.child = false
					})

					if (!item.child) {
						item.child = {
							lastOpt: {
								orderBy: getOrder('endDate'),
								isExcel: 0,
								isDealExcel: 0,
								paging: getPaging(0, 3),
								memberSeq: item.memberSeq,
							},
							res: [],
						}
						ths.child.changePage(item, 1)
					} else item.child = false
				},
				changePage(item, page = 1) {
					//기존 딜 리스트를 조회한다
					const data = item.child.lastOpt
					data.paging.pageNo = page - 1

					postApi('/hottistSelect/penaltyHistList', data).then(res => {
						//받아오는 데 성공했으면 포맷 조정
						const baseIdx = res.paging.totalCnt - res.paging.currentPage * data.paging.pageSize
						res.list = res.list.map((v, i) => {
							v.idx = baseIdx - i
							v.startDate = v.startDate.substr(0, 10)
							v.endDate = v.endDate.substr(0, 10)
							v.updateDt = v.updateDt.replace('T', ' ')
							v.penaltyType = getOptsFromValue(ths.penaltyTypeOpts, v.penaltyType).label
							return v
						})
						//list[], paging{}, isShow, lastOpt{}

						item.child.title = `${item.memberName}의 페널티 이력`
						item.child.res = res
					})
				},
			},
		}
	},
	methods: {
		search() {
			const error = [],
				opt = this.opt,
				data = {
					saveStatus: 'Y',
					paging: getPaging(),
					orderBy: getOrder('updateDt'),
					state: opt.state,
				},
				dateType = getDropdownValue(opt.dateType),
				penaltyType = getDropdownValue(opt.penaltyType),
				penaltyReason = getDropdownValue(opt.penaltyReason),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			if (opt.period) {
				if (opt.startDate > opt.endDate) error.push('요청 기간을 확인해주세요')
				else {
					data.startDate = opt.startDate.get_time('d', 0, '')
					data.endDate = opt.endDate.get_time('d', 0, '')
				}
			}

			if (dateType) data.dateType = dateType
			if (penaltyType) data.penaltyType = penaltyType
			if (penaltyReason) data.penaltyReason = penaltyReason

			if (searchVal) {
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
				if (data.searchKey.length > 1) data.searchKey.splice(data.searchKey.length - 1, 1)
				//메모가 들어가면 검색이 이상해진다?
			}

			this.lastOpt = data
			this.changePage(1)
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1
			postApi('/hottistSelect/penaltyList', addExcelData(this, ex)).then(v => {
				if (!ex) {
					v.list.map(v => {
						;['startDate', 'endDate'].map(k => {
							v[k] = v[k].substr(0, 10)
						})
						v.endDateVal = v.endDate
						v.endDate = v.endDate == '2099-12-31' ? '영구적용' : v.endDate

						v.updateDt = v.updateDt.replace('T', ' ')
						v.penaltyCnt += '회'

						v.child = false

						return v
					})
					this.res = v
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			ex.dropdown(opt.dateType, dateTypeOpts, '날짜 형식')
			ex.date(opt.startDate, opt.endDate)
			const state = opt.state.map(v => getOptsFromValue(stateOpts, v).label).join(' + ')
			if (state) ex.key('페널티 상태', state)
			if (opt.penaltyType) ex.dropdown(opt.penaltyType, this.penaltyTypeOpts, '페널티 유형')
			if (opt.penaltyReason) ex.dropdown(opt.penaltyReason, this.penaltyReasonOpts, '페널티 사유')
			ex.search(opt.searchKey, searchKeyOpts)

			reload(this, false, ex)
		},
		addPenalty() {
			this.penaltyAddPopup.open()
		},
		editPenalty(item) {
			this.penaltyEditPopup.open(item)
		},
		removePenalty(item) {
			confirm(
				`페널티를 삭제하면 서비스 제한이 즉시 해제되고 페널티 이력에서도 완전히 삭제됩니다.\r\n${item.memberName}(${item.currentInstaId}) 핫티스트의 페널티를 삭제하시겠습니까?`,
				() => {
					putApi('/hottistHandle/updatePenalty', {
						hottistPenaltyInfoSeq: item.hottistPenaltyInfoSeq,
						saveStatus: 'N',
						histKey: item.histKey,
						updateAdminSeq: layout.user.seqNo,
						memberSeq: item.memberSeq,
					}).then(() => {
						reload(this)
					})
				}
			)
		},
	},
	computed: {
		popupReasonOpts() {
			return this.penaltyReasonOpts ? [...this.penaltyReasonOpts].splice(1) : []
		},
		popupTypeOpts() {
			return this.penaltyTypeOpts ? [...this.penaltyTypeOpts].splice(1) : []
		},
	},
	mounted() {},
	created() {
		ths = this
		getConfig('penalty_type, penalty_reason')
			.then(res => {
				;['Type', 'Reason'].map(k => {
					this[`penalty${k}Opts`] = res[`penalty_${k.toLowerCase()}`]
					this.opt[`penalty${k}`] = this[`penalty${k}Opts`][0]
					this.defOpt[`penalty${k}`] = this[`penalty${k}Opts`][0]
				})
			})
			.then(() => {
				this.search()
			})
	},
}
</script>

<style></style>
