<template>
	<basePopup :cancelFunc="cancel" :okFunc="save" okText="등록" title="페널티 등록하기">
		<h6>페널티 유형</h6>
		<vSelect
			class="inline-block ml-7 pl-0.5 w-6/7"
			v-model="input.penaltyType"
			:clearable="false"
			:options="typeOpts"
		/>

		<h6 class="mt-4">양식 다운로드</h6>
		<b-button class="ml-4" @click="downloadStyle">페널티 등록 양식 다운로드</b-button>

		<h6 class="mt-4">
			● [등록] 후 업로드 성공 여부를 반드시 확인하세요. 실패한 경우 파일 내용을 수정하여 다시 업로드해 주세요.<br />
			● 각 핫티스트의 기존 페널티가 종료되어야 같은 유형으로 다시 등록할 수 있습니다.<br />예) hottist에게 기존
			등록된 ‘신규딜 차단’ 페널티가 종료돼야 재등록 가능<br />● 동일 기간에 서로 다른 유형의 페널티가 겹칠 경우,
			겹치는 기간에는 최근 등록한 유형으로 적용됩니다.
		</h6>

		<h6 class="mt-4">페널티 등록 명단 업로드</h6>
		<div class="flex">
			<b-form-file
				v-model="input.file"
				placeholder="파일을 선택해주세요."
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				browse-text="파일 찾기"
			/>
		</div>
		<clipboard :pr="ths" />
	</basePopup>
</template>

<script>
import { uploadFile } from 'libs/axios'
import basePopup from 'comp/local/basePopup'
import clipboard from 'comp/local/clipboard'

export default {
	props: ['typeOpts'],
	components: { basePopup, clipboard },
	data() {
		return {
			isShow: false,
			title: '',

			input: {
				penaltyType: '',
				file: null,
			},
		}
	},
	methods: {
		open(item) {
			this.input.penaltyType = this.typeOpts[0]
			this.input.file = null
			this.isShow = true

			setTimeout(() => this.clipboard.clear())
		},
		downloadStyle() {
			window.open(`${s3Url}/backoffice/hottistPenalty/sample_excel/hottistPenaltySample.xlsx`, '_blank')
		},
		save() {
			const input = this.input,
				e = [],
				baseData = {
					adminSeq: layout.user.seqNo,
					penaltyType: getDropdownValue(this.input.penaltyType),
				}
			let preFunc

			if (!input.file) {
				if (this.clipboard.e) e.push(this.clipboard.e)
				//엑셀 클립보드로 붙여넣기
				else preFunc = this.clipboard.getPromise(baseData)
			} else
				preFunc = uploadFile(this.input.file, 'penalty').then(url => {
					const endOfDomain = url.indexOf('/')
					return {
						bucketName: url.substr(0, endOfDomain),
						fileName: url.substr(endOfDomain + 1),
						...baseData,
					}
				})

			if (e.length) alert.w(e)
			else {
				preFunc.then(data => {
					postApi('/hottistHandle/insertPenaltyExcel', data).then(() => {
						alert.s('패널티 등록에 성공했습니다.')
						reload(this, true)
						this.isShow = false
					})
				})
			}
		},
		cancel() {
			if (this.input.file)
				confirm('등록을 완료하지 않은 파일이 있습니다.\r\n등록을 취소하시겠습니까?', () => {
					this.isShow = false
				})
			else this.isShow = false
		},
	},
}
</script>
