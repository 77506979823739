<template>
	<basePopup :okFunc="edit" :title="title" okText="수정">
		<h6>적용기간</h6>
		<div class="flex mb-4">
			<datePicker class="w-2/7" model="input.startDate" />
			<span class="p-2 lh-6">~</span>
			<datePicker v-show="!isEternalPenalty" class="w-2/7" model="input.endDate" />
			<!--이상하게 disabled가 안 먹힌다..?-->
			<b-form-input v-show="isEternalPenalty" class="w-2/7" disabled />
			<b-form-checkbox class="top-1 ml-2" v-model="isEternalPenalty">영구적용</b-form-checkbox>
		</div>

		<h6>페널티 사유</h6>
		<vSelect v-model="input.penaltyReason" :clearable="false" :options="reasonOpts" />

		<h6 class="mt-4">페널티 수정 메모</h6>
		<b-form-textarea
			class="resize-none"
			v-model="input.memo"
			placeholder="페널티 수정 이유 등 메모를 작성하세요."
			rows="8"
		/>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

export default {
	props: ['reasonOpts'],
	components: { basePopup },
	data() {
		return {
			isShow: false,
			title: '',

			hottId: -1,
			isEternalPenalty: false,
			input: {
				startDate: '',
				endDate: '',
				penaltyReason: '',
				memo: '',
			},
			baseInput: {
				histKey: '',
				hottistPenaltyInfoSeq: '',
				memberSeq: '',
				//이걸 왜 여기서 보내주는지 모르겠네..
			},
		}
	},
	methods: {
		open(item) {
			this.title = `${item.memberName} / ${item.hottId} / ${item.currentInstaId} 핫티스트 페널티 수정`
			this.hottId = item.hottId

			this.input.startDate = new Date(item.startDate)
			this.input.endDate = new Date(item.endDateVal)
			this.isEternalPenalty = item.endDateVal == '2099-12-31'

			this.input.penaltyReason = getOptsFromValue(this.reasonOpts, item.penaltyReason)
			this.input.memo = ''
			Object.keys(this.baseInput).map(k => {
				this.baseInput[k] = item[k]
			})

			this.isShow = true
		},
		edit() {
			const input = this.input
			putApi('/hottistHandle/updatePenalty', {
				...this.baseInput,
				updateAdminSeq: layout.user.seqNo,

				penaltyReason: getDropdownValue(input.penaltyReason),
				memoComment: input.memo,
				startDt: input.startDate.get_time('d'),
				endDt: this.isEternalPenalty ? '2099-12-31' : input.endDate.get_time('d'),
			}).then(() => {
				alert.s('수정이 완료되었습니다.')
				reload(this, true)
				this.isShow = false
			})
		},
	},
}
</script>
